import React, { useState, useEffect } from 'react';

type Params = {
    id: string | undefined;
}

enum Status {
    Loading = 'loading',
    Approval = 'approval',
    Approved = 'approved',
    Submit = 'submit',
    Denied = 'denied',
    Error = 'error'
}

export default function ImageViewer(params: Params) {
    const { id } = params;
    const [image, setImage] = useState("");
    const [status, setStatus] = useState<Status>(Status.Loading);
    const [csrfToken, setCsrfToken] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [agreedToTerms, setAgreedToTerms] = useState(false);

    useEffect(() => {
        fetch(process.env.API_URL + '/csrf-token', {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                data.csrfToken && setCsrfToken(data.csrfToken)
            })
            .catch(error => {
                setStatus(Status.Error);
                console.error('Error fetching CSRF token:', error)
            })
    }, [id])

    useEffect(() => {
        if (!csrfToken) return;
        if (!id) {
            setStatus(Status.Error);
            return;
        }
        if (id === "8862") {
            setStatus(Status.Loading);
            return;
        }
        if (id === "8863") {
            setStatus(Status.Denied);
            return;
        }
        try {
            fetch(process.env.API_URL + `/get-image/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken
                },
                credentials: 'include'
            })
                .then(response => response.json())
                .then(data => {
                    if (data.done) {
                        setStatus(Status.Denied); // Image already approved but we can use the denied message
                        return;
                    }
                    data.data && setImage(data.data)
                    setStatus(Status.Approval);
                })
                .catch(error => {
                    console.error('Error fetching image:', error);
                    setStatus(Status.Error);
                });
        } catch (error) {
            console.error('Error fetching image:', error);
            setStatus(Status.Error);
        }
    }, [csrfToken]);

    const handleApprove = () => setStatus(Status.Submit);
    const handleDeny = () => {
        fetch(process.env.API_URL + `/approve`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-csrf-token': csrfToken
            },
            credentials: 'include',
            body: JSON.stringify({ id, approved: false, firstName, lastName, email, agreedToTerms })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log('Image denied');
                    setStatus(Status.Denied);
                }
            });
    };
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${image}`;
        // parse image type from image base64 string
        const type = image.split(';')[0].split('/')[1];
        try {
            link.download = `${id}.${type}`;
            link.click();
        } catch (error) {
            setStatus(Status.Error);
        }

    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (firstName && lastName && email && agreedToTerms) {
            fetch(process.env.API_URL + `/approve`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken
                },
                credentials: 'include',
                body: JSON.stringify({ id, approved: true, firstName, lastName, email, agreedToTerms })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        console.log('Image approved');
                        setStatus(Status.Approved);
                    }
                });
        } else {
            alert('Please fill out all fields and agree to the terms and conditions.');
        }
    };

    const approval = () => {
        return (
            <div className="p-4 space-y-2">
                <img
                    src={`${image}`}
                    alt="Fetched"
                    className="w-full h-auto object-cover"
                />
                <button
                    onClick={handleApprove}
                    className="w-full px-4 py-1 bg-[#fedb00] text-[#000000] rounded-md text-2xl font-extrabold hover:bg-[#fedb34] focus:outline-none focus:ring-2 focus:ring-[#fedb34] focus:ring-opacity-50 transition"
                >
                    DOWNLOAD PHOTO AND ENTER COMPETITION
                </button>
                <button
                    onClick={handleDeny}
                    className="w-full px-4 py-1 bg-[#fedb00] text-[#000000] rounded-md text-2xl font-extrabold hover:bg-[#fedb34] focus:outline-none focus:ring-2 focus:ring-[#fedb34] focus:ring-opacity-50 transition"
                >
                    REJECT PHOTO
                </button>
                <button
                    onClick={handleDownload}
                    className="w-full px-4 py-1 bg-[#fedb00] text-[#000000] rounded-md text-2xl font-extrabold hover:bg-[#fedb34] focus:outline-none focus:ring-2 focus:ring-[#fedb34] focus:ring-opacity-50 transition"
                >
                    DOWNLOAD PHOTO ONLY
                </button>
            </div>
        )
    }

    const form = () => {
        return (
            <form onSubmit={handleSubmit} className="p-4">
                <img
                    src={`${image}`}
                    alt="Fetched"
                    className="w-full h-auto object-cover border-b-8 border-[#101010]"
                />
                <div className="mb-3">
                    <label htmlFor="firstName" className="block text-base font-extrabold text-[#fdfdfd] mb-1">FIRST NAME</label>
                    <input
                        type="text"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-full px-3 py-2 text-base border border-[#222222] rounded-md focus:outline-none focus:ring-2 focus:ring-[#222222] bg-[#101010] text-[#fdfdfd]"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="lastName" className="block text-base font-extrabold text-[#fdfdfd] mb-1">LAST NAME</label>
                    <input
                        type="text"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-full px-3 py-2 text-base border border-[#222222] rounded-md focus:outline-none focus:ring-2 focus:ring-[#222222] bg-[#101010] text-[#fdfdfd]"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="block text-base font-extrabold text-[#fdfdfd] mb-1">EMAIL</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 text-base border border-[#222222] rounded-md focus:outline-none focus:ring-2 focus:ring-[#222222] bg-[#101010] text-[#fdfdfd]"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            checked={agreedToTerms}
                            onChange={(e) => setAgreedToTerms(e.target.checked)}
                            className="mr-2"
                            required
                        />
                        <span className="text-base text-[#fdfdfd]">I AGREE TO THE <a className="text-[#fedb00]" href='https://www.drmartens.com/uk/en_gb/square-toe-competition-terms-conditions' target='_blank'>TERMS AND CONDITIONS</a></span>
                    </label>
                </div>
                <button
                    type="submit"
                    className="w-full px-4 py-1 bg-[#fedb00] text-[#000000] rounded-md text-2xl font-extrabold hover:bg-[#fedb34] focus:outline-none focus:ring-2 focus:ring-[#fedb34] focus:ring-opacity-50 transition"
                >
                    SUBMIT
                </button>
            </form>
        );
    };

    const loading = () => {
        return (
            <div className="flex justify-center items-center h-auto">
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-[#fedb00]"></div>
            </div>
        );
    }

    const error = () => {
        return (
            <div className="flex justify-center items-center h-auto text-[#fdfdfd] text-lg p-4 text-center">
                ERROR LOADING IMAGE, PLEASE TRY AGAIN.
            </div>
        );
    }

    const denied = () => {
        return (
            <div className="flex justify-center items-center h-full text-[#fdfdfd] text-lg p-4 text-center">
                THANK YOU FOR YOUR RESPONSE.
            </div>
        );
    }

    const approved = () => {
        return (
            <div className="p-4 space-y-2">
                <img
                    src={`${image}`}
                    alt="Fetched"
                    className="w-full h-auto object-cover border-b-8 border-[#101010]"
                />
                <div className="flex justify-center items-center h-auto text-[#fdfdfd] text-lg p-4 text-center">
                    THANKS FOR ENTERING! <br /> YOU CAN DOWNLOAD YOUR PHOTO USING THE BUTTON BELOW.
                </div>
                <button
                    onClick={handleDownload}
                    className="w-full px-4 py-1 bg-[#fedb00] text-[#000000] rounded-md text-2xl font-extrabold hover:bg-[#fedb34] focus:outline-none focus:ring-2 focus:ring-[#fedb34] focus:ring-opacity-50 transition"
                >
                    DOWNLOAD
                </button>
            </div>
        );
    }

    const renderStatus = () => {
        switch (status) {
            case Status.Loading:
                return loading();
            case Status.Approval:
                return approval();
            case Status.Submit:
                return form();
            case Status.Approved:
                return approved();
            case Status.Denied:
                return denied();
            case Status.Error:
                return error();
            default:
                return loading();
        }
    }

    return (
        <div className="flex flex-col items-center justify-start min-h-screen bg-[#fedb00] p-4 tracking-[0.015625em]">
            <div className="w-full max-w-md bg-[#101010] rounded-lg shadow-lg shadow-[#101010] border-2 border-[#101010] overflow-hidden mb-4">
                {renderStatus()}
            </div>
        </div>
    );
};