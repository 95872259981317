import React, { useState } from 'react';
import ImageViewer from './Components/ImageViewer';

const App = () => {
    // get id from URL
    const id = window.location.pathname.split('/').pop();
    return (
        <ImageViewer id={id} />
    );
};

export default App;